@font-face {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 300;
  src: local('myriad-set-light'),
    url('./assets/MyriadPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  src: local('myriad-set'),
    url('./assets/MyriadPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 500;
  src: local('myriad-set-semibold'),
    url('./assets/MyriadPro-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Myriad Pro';
  font-weight: 600;
  src: local('myriad-set-bold'),
    url('./assets/MyriadPro-Bold.ttf') format('truetype');
}

html {
  background-color: #1c1c1c;
}

body {
  font-size: 16px;
  font-family: 'Myriad Pro', museo-sans, Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #323232;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: default;
  color: inherit;
}

a:hover {
  opacity: 0.8;
}

div[class^='Snackbar_snackbar-wrapper'] {
  z-index: 1100;
}
